import React from "react"

const Next = ({ ...props }) => {
	return (     
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207 124">
        <path fill="#fff" d="M48.9421964 32.6320058h38.9589621v3.0816363h-35.387724v23.1933682h33.2774468v3.0816363H52.5134345v25.4640475H88.306981v3.0816362H48.9421964V32.6320058Zm42.4490362 0h4.1393895L113.8738 58.0960534l18.749001-25.4640476L158.124498.28680981 116.22757 60.7722112l21.589759 29.7621191h-4.301719L113.8738 63.4483691 94.1508254 90.5343303h-4.2205539l21.7520865-29.7621191-20.2911254-28.1402054Zm47.9682224 3.0816362v-3.0816362h44.396984v3.0816363h-20.453456v54.8206882h-3.571238V35.7136421l-20.37229-1e-7ZM.20292365 32.6320058h4.46404776L66.2235778 124.303087 40.785176 90.5343303 3.93649086 37.0111732l-.16232901 53.5231571H.20292365zM183.396622 86.5227221c.738316 0 1.276852-.5626146 1.276852-1.2896851 0-.7270712-.538536-1.2896857-1.276852-1.2896857-.729629 0-1.276852.5626145-1.276852 1.2896857 0 .7270705.547223 1.2896851 1.276852 1.2896851Zm3.509171-3.3929986c0 2.1465914 1.554806 3.5487995 3.821869 3.5487995 2.414726 0 3.873985-1.445486 3.873985-3.9556131v-8.8373764h-1.945679v8.8287207c0 1.3935531-.703571 2.1379357-1.945679 2.1379357-1.111816 0-1.867504-.692449-1.893562-1.7224664h-1.910934Zm10.240871-.1125224c.138978 2.2331467 2.006481 3.6613219 4.786022 3.6613219 2.970635 0 4.829453-1.4974196 4.829453-3.886368 0-1.8782674-1.0597-2.9169399-3.630775-3.5141773l-1.381085-.3375685c-1.632981-.380847-2.293121-.8915279-2.293121-1.7830551 0-1.1252298 1.024955-1.8609561 2.562389-1.8609561 1.459259 0 2.466843.7184155 2.64925 1.8696111h1.893562c-.112919-2.1033129-1.971737-3.5834218-4.516754-3.5834218-2.73611 0-4.560185 1.4801089-4.560185 3.7046005 0 1.8349889 1.033643 2.9255957 3.300706 3.453588l1.615608.3895021c1.659039.3895028 2.388668.9607731 2.388668 1.9128897 0 1.1079183-1.14656 1.9128897-2.710052 1.9128897-1.676411 0-2.840344-.7530377-3.005379-1.9388562h-1.928307Z"/>
      </svg>
      
        
	)
}

export default Next
